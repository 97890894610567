import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

const handleLogout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.location.replace(
    `/auth/login?redirect_url=${encodeURIComponent(window.location.pathname)}`
  );
};

const ISSERVER = typeof window === 'undefined';
let headerAccessToken;
if (!ISSERVER) {
  headerAccessToken = localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : '';
}
axiosInstance.defaults.headers.common['authorization'] = 'Bearer ' + headerAccessToken;
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['Accept'] = '*/*';

(async () => {
  axiosInstance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([401].includes(response?.status)) {
        enqueueSnackbar('Please Login', { variant: 'error' });
        handleLogout();
        return;
      }
      if ([403].includes(response?.status)) {
        enqueueSnackbar('Access Denied', { variant: 'error' });
        handleLogout();
        return;
      }
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        enqueueSnackbar('Please Login', { variant: 'error' });
      }
      if (error.response?.status === 403) {
        enqueueSnackbar('Access Denied', { variant: 'error' });
      }

      if ([401, 403].includes(error.response?.status)) {
        handleLogout();
      }
      return Promise.reject(error);
    }
  );
})();
export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
