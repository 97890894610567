import { useSnackbar } from 'notistack';
import { useState, createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'src/routes/hooks';
import axiosInstance from 'src/utils/axios';
import { getErrorMessage } from 'src/utils/helpers';
import { useGlobalContext } from './GlobalContext';

export const ResourceHubContext = createContext({});

export const useResourceHubContext = () => {
  return useContext(ResourceHubContext);
};

export const ResourceHubProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useGlobalContext();
  const { moduleSlug } = useParams();
  const searchParams = useSearchParams();
  const whichMode = searchParams.get('mode');

  const [mode, setMode] = useState(null);
  const [resourceHub, setResourceHub] = useState(null);
  const [resourceHubSidebar, setResourceHubSidebar] = useState(null);
  const [moduleSections, setModuleSections] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);

  useEffect(() => {
    if (currentUser?.accountType !== 'admin') return;

    setMode(whichMode || null);
  }, [whichMode]);

  useEffect(() => {
    if (!moduleSlug || !resourceHub?._id) return;
    handleGetSectionsByModule(moduleSlug);
  }, [moduleSlug]);

  const handleEmptyResourceHub = () => {
    setResourceHub(null);
    setResourceHubSidebar(null);
    setModuleSections([]);
    setMode(null);
  };

  const handleGetSectionsByModule = async (path) => {
    try {
      setSelectedModule(resourceHubSidebar?.find((item) => item?.path === path) || null);

      const resp = await axiosInstance.get(`/modules/sections/${path}`, {
        params: {
          resourceHub: resourceHub?._id,
        },
      });
      if (resp.status === 200) {
        setModuleSections(resp.data?.data || []);
      }
      return 'done';
    } catch (error) {
      console.log(error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return 'error';
    }
  };

  const handleGetResourceHub = async (id, params) => {
    try {
      const resp = await axiosInstance.get(`/resource-hub/${id}`, { params });
      if (resp.status === 200) {
        const { sidebar, ...data } = resp.data || {};
        setResourceHub(data?.data || {});
        setResourceHubSidebar(sidebar || []);
        setSelectedModule(
          moduleSlug ? sidebar?.find((item) => item?.path === moduleSlug) : sidebar?.[0] || null
        );

        if (!moduleSlug) {
          setModuleSections(resp.data?.sections || []);
        }
      }
      return 'done';
    } catch (error) {
      console.log(error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return 'error';
    }
  };

  const handelAddEditSidebar = async (data) => {
    try {
      data.resourceHub = resourceHub?._id;
      let resp;
      if (data?.isDelete) {
        resp = await axiosInstance.delete(`/modules/${data._id}`);
      } else if (data._id) {
        resp = await axiosInstance.put(`/modules/${data._id}`, data);
      } else {
        resp = await axiosInstance.post('/modules', data);
      }
      if (resp.status === 200) {
        setResourceHubSidebar(resp.data?.sidebar || []);
      }
      return 'done';
    } catch (error) {
      console.log(error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return 'error';
    }
  };

  const handleEditModuleSection = async ({ action, sectionId, contentId, ...data }) => {
    try {
      const reqObj = { action, ...data };
      let url = `/sections/${sectionId}`;

      if (contentId) {
        url = `/sections/${sectionId}/${contentId}`;
      }

      const resp = await axiosInstance.put(url, reqObj);
      if (resp.status === 200) {
        setModuleSections((prev) => {
          const newSections = prev.map((section) => {
            if (section._id === sectionId) {
              return resp.data?.data || {};
            }
            return section;
          });
          return newSections;
        });
      }

      return 'done';
    } catch (error) {
      console.log(error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return 'error';
    }
  };

  const handleAddNewModuleSection = async (data) => {
    try {
      data.resourceHub = resourceHub?._id;
      data.module = moduleSlug || selectedModule?.path;
      const resp = await axiosInstance.post('/sections', data);
      if (resp.status === 200) {
        setModuleSections([...moduleSections, resp.data?.data || {}]);
      }
      return 'done';
    } catch (error) {
      console.log(error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return 'error';
    }
  };

  const handleDeleteSection = async (sectionId, contentId) => {
    try {
      const resp = await axiosInstance.delete(`/sections/${sectionId}/contents/${contentId}`);
      if (resp.status === 200) {
        if (resp?.data?.data) {
          setModuleSections((prev) => {
            const newSections = prev.map((section) => {
              if (section._id === sectionId) {
                return resp.data?.data || {};
              }
              return section;
            });
            return newSections;
          });
        } else {
          setModuleSections((prev) => {
            const newSections = prev.filter((section) => section._id !== sectionId);
            return newSections;
          });
        }
      }
      return 'done';
    } catch (error) {
      console.log(error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return 'error';
    }
  };

  return (
    <ResourceHubContext.Provider
      value={{
        // state
        resourceHub,
        resourceHubSidebar,
        moduleSections,
        mode,
        moduleSlug,
        selectedModule,

        // methods

        // functions
        handleGetResourceHub,
        handelAddEditSidebar,
        handleEmptyResourceHub,
        handleEditModuleSection,
        handleAddNewModuleSection,
        handleGetSectionsByModule,
        handleDeleteSection,
      }}
    >
      {children}
    </ResourceHubContext.Provider>
  );
};
